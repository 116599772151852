import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HankoAuth from './HankoAuth';
import HankoProfile from './HankoProfile';
import LogoFooter from './LogoFooter';
import CharmingPay from './CharmingPay';

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HankoAuth />} />
          <Route path="/profile" element={<HankoProfile />} />
          <Route path="/pay" element={<CharmingPay />} />
        </Routes>
      </BrowserRouter>
      <div className="logo-footer">
        <LogoFooter />
      </div>
    </div>
  );
}

export default App;