import React, { useEffect, useState } from "react";
// import { Hanko, register } from "./elements/src";
import { Hanko, User, register } from "@teamhanko/hanko-elements";
import styles from "./Todo.module.css";
import { useNavigate } from "react-router-dom";
// import { Hanko, User, UserClient } from "@teamhanko/hanko-frontend-sdk"
import GradientButton, { InviteCom } from "./GradientButton";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { all } from "@teamhanko/hanko-elements/i18n/all";
import { zh } from '../src/i18n/zh'
import { CharmingAPIHost, LoginCharmingHost } from "./AppEnvironment";

const api = process.env.REACT_APP_HANKO_API!
const hankoAPI = new Hanko(api)

var inviteCode: string | any
var myCode: string | any

// console.log = function () {};

const copyText = async (text:string) => {
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    document.execCommand("copy", true, text);
  }
};

function showCopiedToast() {
  if (myCode && myCode !== "") {
    let copy = `https://${LoginCharmingHost}/?code=${myCode}`
    copyText(copy).then(() => {
      toast.success('复制成功，快去分享给好友吧！'); 
    })
  } else {
    toast.error('暂时无法获取邀请链接，请稍后再试！'); 
  }
}

const handleButtonClick = () => {
  // hankoAPI.user.
  // var copy = "https://login.charming.chat?code=" + myCode

  if (myCode == null || myCode === "") {
    var loadinID = toast.loading('生成链接中...');
    // success('复制成功，快去分享给好友吧！'); 
    hankoAPI.user.getCurrent().then((user:any) => {
      const cookie = hankoAPI.token.client.cookie.getAuthCookie()
      console.log(`profile cookie:${JSON.stringify(cookie)}`);
      console.log(`user:${JSON.stringify(user)}`);

      fetch(`https://${CharmingAPIHost}/user`, { //TODO: change to https://api.charming.chat    http://127.0.0.1:8787
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${cookie}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        toast.dismiss(loadinID)
        console.log("获取到用户信息:", data)
        // data.code
        myCode = data?.data?.code
        showCopiedToast()
      })
      .catch(err1 => {
        toast.dismiss(loadinID)
        toast.error(err1.message)
      });
    }).catch((err:any) => {
      toast.error(err.message);
    })
  } else {
    showCopiedToast()
  }
};

function HankoProfile() {
  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);
  const [isobbk, setIsobbk] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  const logout = () => {
    hankoAPI.user.logout().then( ()=>{
      const urlScheme = `oneshot://logout`;
      window.location.href = urlScheme;

      var pr = "/"
      if (inviteCode !== null && inviteCode !== "") {
        pr = "/?code=" + inviteCode
      }
      navigate(pr);
    })
  };

  const pay = () => {
    var pr = "/pay"
    navigate(pr);
  };

  useEffect(() => {
    register(api, {shadow: true, translations: {...all, zh}}).catch(setError);
  }, []);

  // 进入页面必展示
  useEffect(()=>{
    onlod()
  }, [])

  const checkUser = async () => {
    const _user = await hankoAPI.user.getCurrent()
    console.log(`检查user:${JSON.stringify(_user)}`);
    setUser(_user);
  }

  const onlod = async () => {
    await checkUser()
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log(`code: ${code}`); // Outputs "1762H" if the URL is https://login.charming.chat?code=1762H
    inviteCode = code

    const isobbk = urlParams.get('isobbk');
    console.log(`isobbk: ${isobbk}`);
    setIsobbk(isobbk === '1')
  }

  return (
    <>
      <nav className={styles.nav}>
        <button onClick={logout} className={styles.button}>
          Log out
        </button>
        <button disabled className={styles.button}>
          User
        </button>
        {!isobbk && (
          <button onClick={pay} className={styles.button}>
            Purchase
          </button>
        )}
      </nav>
      <div className={styles.content}>
        <h1 className={styles.headline}>Profile</h1>
        <div className={styles.error}>{error?.message}</div>
        {/* <hanko-profile lang='zh'/> */}
        <hanko-profile lang='zh'/>
      </div>
      {!isobbk && <InviteCom />}
      <div className="content">
        {!isobbk && <GradientButton onClick={handleButtonClick} />}
        <ToastContainer />
      </div>
    </>
  );
}

export default HankoProfile;
