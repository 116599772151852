import React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// import styles from "./Todo.module.css";
import "./index.css";
// import { Hanko } from '@teamhanko/hanko-frontend-sdk';
// import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { MouseEventHandler } from "react";

interface GradientButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}


const Button = styled.button`
  background-image: linear-gradient(135deg, #FE4F2D 0%, #0AA0ED 100%);
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 12px 24px;
  text-decoration: none;
  transition: background-image 0.2s ease-in-out;
  width: 100%;
  max-width: 500px;
  min-width: 330px;

  &:hover {
    background-image: linear-gradient(135deg, #0AA0ED 0%, #FE4F2D 100%);
  }
`;

// define a component that renders markdown text as HTML, paramater is a click function
const GradientButton = ({onClick}: GradientButtonProps) => {
  return (
    <Button onClick={onClick}>
      去邀请
    </Button>
  );
};

// const GradientButton = ({onClick}) => {
//   return (
//     <Button onClick={onClick}>
//       去邀请
//     </Button>
//   );
// };

// const api = process.env.REACT_APP_HANKO_API!
// const hankoAPI = new Hanko(api)

export default GradientButton;

export const InviteCom = () => {

  // text hook
  const [pre_intro_text, setPre_intro_text] = React.useState(`
  ## 🌀 映相 — Swapr 是一款
  - AI 换脸工具
  \
  &nbsp;
  `)

  const [intro_text, setintro_text] = React.useState( `
  ## ✨  立即注册
  - 新用户见面礼：**6** 张免费额度
  - 每日 **3** 张免费额度

  \
  &nbsp;
  \
  &nbsp;  

  ## 🎁 邀请好友
  - 每邀请一人，获得奖励 **4** 张额度
  - 被邀请人购买额度，你将获得 **20%** 的奖励
  `)

  //execute script when page is loaded
  // React.useEffect(() => {
  //   // use fetch to call a get api
  //   // fetch('https://api.github.com/repos/remarkjs/react-markdown')

  //   hankoAPI.user.getCurrent().then((user:any) => {
  //     const cookie = hankoAPI.token.client._getAuthCookie()
  //     console.log(`user:${JSON.stringify(user)}`);
  
  //     fetch('https://api.charming.chat/user', { //TODO: change to https://api.charming.chat    http://127.0.0.1:8787
  //       method: 'GET',
  //       headers: {
  //         'Authorization': `Bearer ${cookie}`,
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log("获取到用户信息:", data)
  //       setPre_intro_text(data.pre_intro_text)
  //       setintro_text(data.intro_text)
  //     })
  //     .catch(err1 => {
  //       toast.error(err1.message)
  //     });
  //   }).catch((err:any) => {
  //     toast.error(err.message);
  //   })
    
  // });

  return (
      <div className="content shadow">
        <ReactMarkdown children={pre_intro_text} remarkPlugins={[remarkGfm]} />
        <ReactMarkdown children={intro_text} remarkPlugins={[remarkGfm]} />
      </div>
  );
};