import React, { useState, useEffect, useMemo } from 'react';
import { Button, message, Card } from 'antd';
import axios from 'axios';
import { Hanko } from "@teamhanko/hanko-elements";
import styles from "./Todo.module.css";
import { useNavigate } from "react-router-dom";
import Confetti from 'react-confetti';
import { AlipayCircleFilled } from '@ant-design/icons';
import queryString from 'query-string';
import { CharmingAPIHost } from './AppEnvironment';

const API = {
  // createOrder: 'http://127.0.0.1:8787/createOrder12/alipay',
  createOrder: `https://${CharmingAPIHost}/createOrder12/alipay`,
  create50Order: `https://${CharmingAPIHost}/createOrder50/alipay`,
  create100Order: `https://${CharmingAPIHost}/createOrder100/alipay`,
  // createSubOrder: 'http://127.0.0.1:8787/createSubOrder/alipay',
  createSubOrder: `https://${CharmingAPIHost}/createSubOrder/alipay`,
  // queryOrder: 'http://127.0.0.1:8787/queryOrder',// TODO: change to https://api.charming.chat
  queryOrder: `https://${CharmingAPIHost}/queryOrder`
};

const api = process.env.REACT_APP_HANKO_API!;

const CharmingPay = () => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [orderId, setOrderId] = useState('');
  const [paid, setPaid] = useState(false);
  const [polling, setPolling] = useState(false);
  const [confetti, setConfetti] = useState(false);

  useEffect(() => {
    if (polling) {
      queryOrder();
    }
  }, [polling]);
  
  // 在组件卸载时清除计时器
  useEffect(() => {
    return () => {
      setPolling(false);
    };
  }, []);
  
  const profile = () => {
    var pr = "/profile"
    setPolling(false);
    navigate(pr);
  };

  const hankoAPI = useMemo(() => new Hanko(api), []);

  // // 处理二维码链接
  const handleQrCodeClick = async () => {
    await handleJumpToAlipay();
  };


  // 创建订单
  const createOrder = async (api: string) => {
    setLoading(true);

    try {

      var cookie = hankoAPI.token.client.cookie.getAuthCookie()
      console.log(`cookie:${JSON.stringify(cookie)}`);
      
      if (cookie == null || cookie === "") {
        const values = queryString.parse(window.location.search);
        cookie = (values.token ?? "") as string;
        console.log(`读取链接中的 cookie:${cookie}`);
      }
      
      const res = await axios.post(`${api}?token=${cookie}`);

      // 生成二维码图片
      setQrCode(res.data.info.qr);
      setOrderId(res.data.aoid);
      setLoading(false);
      setPolling(true);
      console.log(`orderId:${orderId}`);
    } catch (err) {
      setLoading(false);
      message.error('创建订单失败，请登录');
    }
  };

  const requestQueryOrder = async () => {
    try {
      const cookie = hankoAPI.token.client.cookie.getAuthCookie()
      const res = await axios.get(`${API.queryOrder}?token=${cookie}&orderId=${orderId}`);
      const status = res.data.status;
      return status;
    } catch (err) {
      message.error('查询订单状态失败');
    }
  }

  // 轮询订单状态
  const queryOrder = async () => {
    const status = await requestQueryOrder()
    if (status === 'success') {
      message.success('🎉 购买成功 🎉');
      setPaid(true);
      setPolling(false);
      setConfetti(true);
      setTimeout(() => {
        setConfetti(false);
        setPaid(false);
        setOrderId('');
        setQrCode('');
      }, 6000);
    } else if (status === 'not_exist' || status === 'expire') {
      message.error('订单已过期或不存在');
      setPolling(false);
    } else {
      if (polling) {
        setTimeout(queryOrder, 500);
      }
    }
  };

    // 点击跳转支付宝
    const handleJumpToAlipay = async () => {
      if (qrCode) {
        window.open(qrCode, '_blank');
      }
    };

  // 点击我已付款按钮
  const handlePaid = async () => {
    try {
      const status = await requestQueryOrder()
      message.info(`当前订单状态：${status}`);
    } catch (err) {
      message.error('查询订单状态失败');
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <nav className={styles.nav}>
        <button disabled className={styles.button}>
          Purchase
        </button>
        <button onClick={profile} className={styles.button}>
          User
        </button>
      </nav>
      <h1>Purchase</h1>
      {confetti && <Confetti />}
      {!qrCode && !paid && (
        <Card
          hoverable
          style={{
            width: 300,
            borderRadius: 10,
            margin: '100px auto',
            background: 'linear-gradient(to right, #4E5673, #0B2E42)',
            fontWeight: 'bold',
          }}
          bodyStyle={{ padding: 20 }}
          onClick={() => createOrder(API.createOrder)}
          loading={loading}
        >
          {/* // TODO: 使用支付宝 */}
          {/* <div className="gradient-text">暂时无法支付</div> */}
          <div className="gradient-text">20 Face Swap</div>
          <div className="gradient-text" style={{ fontSize: 30, marginTop: 10 }}>
            ￥19.9
          </div>
          <div style={{ marginTop: 10, color: "white" }}>
            <AlipayCircleFilled style={{ fontSize: 24 }} />
          </div>
      </Card>
      )}
      {!qrCode && !paid && (
        <Card
          hoverable
          style={{
            width: 300,
            borderRadius: 10,
            margin: '100px auto',
            background: 'linear-gradient(to right, #4E5673, #0B2E42)',
            fontWeight: 'bold',
          }}
          bodyStyle={{ padding: 20 }}
          onClick={() => createOrder(API.create50Order)}
          loading={loading}
        >
          <div className="gradient-text">50 Face Swap</div>
          <div className="gradient-text" style={{ fontSize: 30, marginTop: 10 }}>
            ￥45.9
          </div>
          <div style={{ marginTop: 10, color: "white" }}>
            <AlipayCircleFilled style={{ fontSize: 24 }} />
          </div>
      </Card>
      )}
      {!qrCode && !paid && (
        <Card
          hoverable
          style={{
            width: 300,
            borderRadius: 10,
            margin: '100px auto',
            background: 'linear-gradient(to right, #4E5673, #0B2E42)',
            fontWeight: 'bold',
          }}
          bodyStyle={{ padding: 20 }}
          onClick={() => createOrder(API.create100Order)}
          loading={loading}
        >
          <div className="gradient-text">100 Face Swap</div>
          <div className="gradient-text" style={{ fontSize: 30, marginTop: 10 }}>
            ￥66.9
          </div>
          <div style={{ marginTop: 10, color: "white" }}>
            <AlipayCircleFilled style={{ fontSize: 24 }} />
          </div>
      </Card>
      )}
      {qrCode && !paid && (
        <div>
          <img src={`https://xorpay.com/qr?data=${qrCode}`} alt="qr-code" onClick={handleQrCodeClick}/>
          <div style={{ margin: '20px 0' }}>
            <Button type="primary" onClick={handleJumpToAlipay}>
              手机跳转支付宝
            </Button>
          </div>
          <div style={{ margin: '20px 0' }}>
            <Button type="primary" onClick={handlePaid}>
              我已付款
            </Button>
          </div>
        </div>
      )}
      {paid && (
        <div>
          <h2>购买成功！</h2>
        </div>
      )}
    </div>
  );
};

export default CharmingPay;