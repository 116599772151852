import { Translation } from "@teamhanko/hanko-elements"; 

export const zh: Translation = {
  headlines: {
    error: "发生错误",
    loginEmail: "登录或注册",
    loginEmailNoSignup: "登录",
    loginFinished: "登录成功",
    loginPasscode: "输入验证码",
    loginPassword: "输入密码",
    registerAuthenticator: "创建密钥",
    registerConfirm: "创建账号？",
    registerPassword: "设置新密码",
    profileEmails: "电子邮件",
    profilePassword: "密码",
    profilePasskeys: "密钥",
    isPrimaryEmail: "主要电子邮件地址",
    setPrimaryEmail: "设定主要电子邮件地址",
    createEmail: "输入新电子邮件",
    createUsername: "输入新用户名",
    emailVerified: "已验证",
    emailUnverified: "未验证",
    emailDelete: "删除",
    renamePasskey: "重命名密钥",
    deletePasskey: "删除密钥",
    lastUsedAt: "最后使用在",
    createdAt: "创建于",
    connectedAccounts: "连接的账户",
    deleteAccount: "删除帐户",
    accountNotFound: "未找到帐户",
    signIn: "登录",
    signUp: "注册",
    selectLoginMethod: "选择登录方法",
    setupLoginMethod: "设置登录方法",
    lastUsed: "最后一次查看",
    ipAddress: "IP 地址",
    revokeSession: "撤销会话",
    profileSessions: "会话",
  },
  texts: {
    enterPasscode: "输入发送到“{emailAddress}”的验证码。",
    enterPasscodeNoEmail: "输入发送到您的主要电子邮件地址的验证码。",
    setupPasskey:
      "使用密钥轻松安全地登录您的账户。注意：您的生物识别数据仅存储在您的设备上，永远不会与任何人共享。",
    createAccount: "没有“{emailAddress}”的账户存在。你想要创建一个新账户吗?",
    passwordFormatHint: "必须长在{minLength}和{maxLength}字符之间。",
    setPrimaryEmail: "将此电子邮件地址设置为用于联系您。",
    isPrimaryEmail: "如有必要，此电子邮件地址将用于联系您。",
    emailVerified: "此电子邮件地址已经过验证。",
    emailUnverified: "此电子邮件地址尚未验证。",
    emailDelete:
      "如果您删除此电子邮件地址，将无法再用于登录您的账户。可能已经用此电子邮件地址创建的密钥将保持完整。",
    renamePasskey: "为密钥设定名称，帮助您识别其所存储的位置。",
    deletePasskey:
      "从您的账户中删除此密钥。请注意，密钥将继续存在于您的设备上，也需要在那里被删除。",
    deleteAccount: "您确定要删除此账号吗？所有数据将立即被删除，且无法恢复。",
    noAccountExists: '没有账户"{emailAddress}"。',
    selectLoginMethodForFutureLogins:
      "请选择以下登录方法之一以供将来登录使用。",
    howDoYouWantToLogin: "您想如何登录？",
  },
  labels: {
    or: "或",
    no: "否",
    yes: "是",
    email: "电子邮件",
    continue: "继续",
    skip: "跳过",
    save: "保存",
    passkey: "密码",
    passcode: "访问码",
    password: "密码",
    signInPassword: "使用密码登录",
    signInPasscode: "使用验证码登录",
    forgotYourPassword: "忘记密码了吗？",
    back: "返回",
    signInPasskey: "使用密钥登录",
    registerAuthenticator: "创建密钥",
    signIn: "登录",
    signUp: "注册",
    sendNewPasscode: "发送新代码",
    passwordRetryAfter: "{passwordRetryAfter}后重试",
    passcodeResendAfter: "{passcodeResendAfter}后请求新的代码",
    unverifiedEmail: "未验证",
    primaryEmail: "主要的",
    setAsPrimaryEmail: "设为主要",
    verify: "验证",
    delete: "删除",
    newEmailAddress: "新电子邮件地址",
    newPassword: "新密码",
    rename: "重命名",
    newPasskeyName: "新密钥名称",
    addEmail: "添加电子邮件",
    createPasskey: "创建密钥",
    webauthnUnsupported: "您的浏览器不支持密钥",
    signInWith: "通过 {provider} 登录",
    deleteAccount: "是的，删除此帐户。",
    emailOrUsername: "电子邮件或用户名",
    username: "用户名",
    optional: "可选的",
    dontHaveAnAccount: "没有账号？",
    alreadyHaveAnAccount: "已有账号？",
    changeUsername: "更改用户名",
    setUsername: "设置用户名",
    changePassword: "更改密码",
    setPassword: "设置密码",
    revoke: "撤销",
    currentSession: "当前会话",
  },
  errors: {
    somethingWentWrong: "发生技术错误。请稍后再试。",
    requestTimeout: "请求超时。",
    invalidPassword: "邮箱或密码错误。",
    invalidPasscode: "提供的验证码不正确。",
    passcodeAttemptsReached: "验证码输入次数过多。请请求新的验证码。",
    tooManyRequests: "请求太频繁。请稍后重试。",
    unauthorized: "您的会话已过期。请再次登录。",
    invalidWebauthnCredential: "此密钥已无法使用。",
    passcodeExpired: "验证码已过期。请请求新的验证码。",
    userVerification:
      "需要用户验证。请确保你的验证设备已经用PIN或生物识别保护。",
    emailAddressAlreadyExistsError: "电子邮件地址已存在。",
    maxNumOfEmailAddressesReached: "不能添加更多的电子邮件地址。",
    thirdPartyAccessDenied:
      "访问被拒绝。该请求已被用户取消或者供应商由于其他原因拒绝了访问。",
    thirdPartyMultipleAccounts: "无法确定账户。电子邮件地址被多个账户使用。",
    thirdPartyUnverifiedEmail:
      "需要电子邮件验证。请与您的提供商验证使用的电子邮件地址。",
    signupDisabled: "帐户注册被禁用。",
  },
  flowErrors: {
    technical_error: "发生技术错误。请稍后再试。",
    flow_expired_error: "会话已过期，请点击按钮重新启动。",
    value_invalid_error: "输入的值无效。",
    passcode_invalid: "提供的密码不正确。",
    passkey_invalid: "此密码无法再使用。",
    passcode_max_attempts_reached:
      "密码输入错误次数太多。请请求一个新的验证码。",
    rate_limit_exceeded: "请求过多。请等待重复所请求的操作。",
    unknown_username_error: "用户名未知。",
    username_already_exists: "用户名已被使用。",
    invalid_username_error: "用户名只能包含字母、数字和下划线。",
    email_already_exists: "电子邮件已被使用。",
    not_found: "未找到请求的资源。",
    operation_not_permitted_error: "不允许此操作。",
    flow_discontinuity_error: "由于用户设置或提供商配置，流程无法继续。",
    form_data_invalid_error: "提交的表单数据包含错误。",
    unauthorized: "您的会话已过期。请再次登录。",
    value_missing_error: "值丢失。",
    value_too_long_error: "值太长。",
    value_too_short_error: "值太短。",
  },
};
